


















































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import configProvider from "@common/services/configProvider";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";

export default Vue.extend({
  components: { GlobalErrorSnackBar },
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      logoUrl: configProvider.get("LOGO_URL"),
      data: {},
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const result = await coreApiClient.callRaw(
          "auth",
          "forgotPassword",
          undefined,
          {
            email: this.data.email,
          }
        );
        if (result) {
          alert(
            "An email has been sent to your email address. Please check your email to reset your password."
          );
          this.$router.replace("/login");
        }
      } catch (err) {
        const theErr = err as any;
        if (theErr.response?.body?.message) {
          this.error = theErr.response.body.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
